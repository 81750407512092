import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { List } from 'immutable';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PhotoEvent } from '../../classes/photos/photoevent/photo-event';
import { PhotoEventService } from '../../services/photos/photo-event.service';
import { ErrorPhotoEvent } from '../../classes/photos/photoevent/error/error-photo-event';
import { DeletedFromLocalStoragePhotoEvent } from '../../classes/photos/photoevent/deleted-from-local-storage-photo-event';
import { LocallyStoredPhotoEvent } from '../../classes/photos/photoevent/locally-stored-photo-event';
import { IDBSpace } from '../../services/app-storage.service';
import { AppNavigationService } from '../../services/app-navigation.service';
import { ProNumber } from '../../classes/pronumber';
import { AppConstantsService } from '../../services/app-constants.service';

@Component({
  selector: 'ins-photos-status',
  templateUrl: './photos-status.component.html',
  styleUrls: ['./photos-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhotosStatusComponent implements OnInit {
  photoError$: Observable<List<ErrorPhotoEvent>>;
  photoUploaded$: Observable<List<PhotoEvent>>;

  totalLocalStoragePhotosCount$: Observable<number>;
  photoDeletedFromLs$: Observable<List<DeletedFromLocalStoragePhotoEvent>>;
  photoStoredLs$: Observable<List<LocallyStoredPhotoEvent>>;
  photoSpace$: Observable<IDBSpace>;
  photoCleanupStrategyDays: string;

  constructor(
    // private photoUploadService: PhotoUploadService,//please dont, push events from the uploadService
    // private mobileWebBrowserService: MobileWebBrowserService,//please dont, push events from the uploadService
    private appConstantsService: AppConstantsService,
    private dialogRef: MatDialogRef<PhotosStatusComponent>,
    private photoEventService: PhotoEventService,
    private appNavigation: AppNavigationService,
  ) {}

  ngOnInit(): void {
    this.photoCleanupStrategyDays = this.appConstantsService.getPhotoCleanupStrategyDays();

    this.photoError$ = this.photoEventService.photoErrorEvents$;
    this.photoUploaded$ = this.photoEventService.photoUploadedToDmsEvents$;
    this.photoDeletedFromLs$ = this.photoEventService.photoDeletedFromLsEvents$;
    this.totalLocalStoragePhotosCount$ = this.photoEventService.totalLocalStoragePhotosCount$;
    this.photoStoredLs$ = this.photoEventService.photosStoredLsEvents$;
    this.photoSpace$ = this.photoEventService.photoStorageSpace$;
  }

  static getMatDialogConfig(): MatDialogConfig {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.autoFocus = true;
    matDialogConfig.disableClose = false;
    matDialogConfig.width = '80vw';
    matDialogConfig.height = '80vh';
    return matDialogConfig;
  }

  close() {
    this.dialogRef.close();
  }

  onProLinkToInspectionClicked(proNumber: ProNumber) {
    this.appNavigation.navigateToShipmentDetails(proNumber);
    this.close();
  }

  onProLinkToShipmentClicked(proNumber: ProNumber) {
    this.appNavigation.navigateToShipmentDetails(proNumber);
    this.close();
  }

  onProLinkClicked(photoEvent: PhotoEvent) {
    this.appNavigation.navigateToShipmentDetails(photoEvent.photo.getProNumber());
    this.close();
  }
}
