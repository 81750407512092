import { Injectable } from '@angular/core';
import {
  ElasticSearchApiService,
  NmfcItemSearchRecord,
  XrtAttributeFilter,
  XrtNmfcItemSearchFilter,
  XrtNmfcItemSearchResp,
  XrtNmfcItemSearchRqst,
  XrtSearchQueryHeader,
  XrtSortExpression,
} from '@xpo-ltl/sdk-elasticsearch';
import { map, Observable } from 'rxjs';
import { ComparatorUtils } from '../../../classes/utils/comparator-utils';

@Injectable({ providedIn: 'root' })
export class ElasticSearchApiWrapperService {
  private lastLoadedNmfcItems: NmfcItemSearchRecord[] = [];

  constructor(private elasticSearchApiService: ElasticSearchApiService) {}

  getLastLoadedNmfcItems(): NmfcItemSearchRecord[] {
    return this.lastLoadedNmfcItems;
  }

  xrtNmfcItemSearch(startsWith: string): Observable<XrtNmfcItemSearchResp> {
    const request: XrtNmfcItemSearchRqst = new XrtNmfcItemSearchRqst();
    const header: XrtSearchQueryHeader = new XrtSearchQueryHeader();
    const filter: XrtNmfcItemSearchFilter = new XrtNmfcItemSearchFilter();
    const expressions: XrtSortExpression[] = [];
    const expression: XrtSortExpression = new XrtSortExpression();
    const id: XrtAttributeFilter = new XrtAttributeFilter();

    header.page = 1;
    header.pageSize = 10000;
    
    expression.Column = 'id';
    expression.IsDescendingSort = false;
    expressions.push(expression);
    header.sortExpressions = expressions;
    id.startsWith = startsWith;
    filter.id = id;

    request.header = header;
    request.filter = filter;

    return this.elasticSearchApiService.xrtNmfcItemSearch(request, { loadingOverlayEnabled: false }).pipe(
      map((resp: XrtNmfcItemSearchResp) => {
        this.lastLoadedNmfcItems = resp.result;
        resp.result = resp.result.filter((record: NmfcItemSearchRecord) => record.expiredInd === false);
        resp.result.sort((record1: NmfcItemSearchRecord, record2: NmfcItemSearchRecord) => {
          if (record1?.itemNbr > record2?.itemNbr) {
            return 1;
          }

          if (record1?.itemNbr < record2?.itemNbr) {
            return -1;
          }

          if (record1?.itemNbr === record2?.itemNbr) {
            return ComparatorUtils.stringNumberCompare(record1?.itemSubNbr, record2?.itemSubNbr);
          }
        });

        return resp;
      })
    );
  }
}
