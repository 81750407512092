<div class="dimensioner-inspection-detail">
  <mat-expansion-panel [(expanded)]="isExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title class="section-label">
        {{ matPanelTitleContent$ | async }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div fxLayout="column" fxLayoutAlign="start stretch">
      <div fxLayout="row" fxLayoutAlign="stretch start">
        <div fxLayout="column" fxFlex="20">
          <div class="label">Length</div>
          <div class="value" data-testid="length">
            {{ (dimensionerInspectionDetail$ | async)?.lengthNbr ?? '-' }}
          </div>
        </div>
        <div fxLayout="column" fxFlex="20">
          <div class="label">Width</div>
          <div class="value" data-testid="width">
            {{ (dimensionerInspectionDetail$ | async)?.widthNbr ?? '-' }}
          </div>
        </div>
        <div fxLayout="column" fxFlex="20">
          <div class="label">Height</div>
          <div class="value" data-testid="heightNbr">
            {{ (dimensionerInspectionDetail$ | async)?.heightNbr ?? '-' }}
          </div>
        </div>
        <div fxLayout="column" fxFlex="20">
          <div class="label">Device Id</div>
          <div class="value" >
            {{ (dimensionerInspectionDetail$ | async)?.deviceId ?? '-' }}
          </div>
        </div>
        <div fxLayout="column" fxFlex="20">
          <div class="label">Device Name</div>
          <div class="value" >
            {{ (dimensionerInspectionDetail$ | async)?.deviceName ?? '-' }}
          </div>
        </div>
      </div>
    </div>
    <div class="dimensioner-inspection-detail__images-container" fxLayout="row" fxLayoutAlign="stretch start" (click)="imagesClick()">
      <div class="dimensioner-inspection-detail__image" *ngFor="let documentWithPhoto of documentWithPhotoArray$ | async">
        <div class="dimensioner-inspection-detail__image-caption label">
          {{ documentWithPhoto.doc.imageTypeCd }}
        </div>
        <img [src]="documentWithPhoto.photo.getBase64data(true)" />
      </div>
    </div>
  </mat-expansion-panel>
</div>