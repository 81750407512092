import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig
} from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { GetInspectionShipmentDetailsResp, InspectionCustGuidelines } from '@xpo-ltl/sdk-inspections';

import { Title } from '@angular/platform-browser';
import { List } from 'immutable';
import { Observable, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { InspectionError } from '../../classes/error/inspection-error';
import { InspectionShipmentDetails } from '../../classes/inspection-shipment-details';
import { MultipleNotificationData } from '../../classes/multiple-notification-data';
import { NotificationData } from '../../classes/notification-data';
import { Photo } from '../../classes/photos/photo';
import { ProNumber } from '../../classes/pronumber';
import { DialogHeader } from '../../enums/dialog-header.enum';
import { DialogMessage } from '../../enums/dialog-message.enum';
import { DmsDocType } from '../../enums/dms-doc-type.enum';
import { DocumentListTitles } from '../../enums/document-list-titles.enum';
import { InspectionShipmentDetailsService } from '../../services/api/inspection-shipment-details.service';
import { AppConstantsService } from '../../services/app-constants.service';
import { AppNavigationService } from '../../services/app-navigation.service';
import { LocationService } from '../../services/location/location.service';
import { PhotoDownloadService } from '../../services/photos/photo-download.service';
import { SnackBarHandlingService } from '../../services/snack-bar-handling.service';
import { RequestValidator } from '../../validators/request.validator';
import { CustomerGuidelinesService } from '../customer-guidelines/services/customer-guidelines.service';
import { LoadingOverlayService } from '../loading-overlay/service/loading-overlay.service';
import { PhotoGalleryService } from '../photo-gallery/photo-gallery.service';
import { CustomerInstructionsAndPalletPricingRatedDialogComponent } from './../../dialogs/customer-instructions-dialog/customer-instructions-dialog.component';
import { ShipmentDetailsCacheService } from '../../services/cache/shipment-details-cache.service';

@Component({
  selector: 'app-shipment-details',
  templateUrl: './shipment-details.component.html',
  styleUrls: ['./shipment-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShipmentDetailsComponent implements OnInit, OnDestroy {
  static readonly OPERATIONS_PHOTOS_DOC_TYPES: DmsDocType[] = [
    DmsDocType.TRAILER_PHOTO,
    DmsDocType.PICKUP_DIMENSIONER_PHOTO,
    DmsDocType.CUBIC_SCAN_DIMENSIONER_PHOTO
  ];
  static readonly INSPECTIONS_PHOTOS_DOC_TYPES: DmsDocType[] = [DmsDocType.INSPECTION_PHOTO];
  static readonly DOCUMENT_DOC_TYPES: DmsDocType[] = [
    DmsDocType.BILL_OF_LANDING,
    DmsDocType.BILL_OF_LANDING_ATTACHMENT,
    DmsDocType.CUSTOMS,
    DmsDocType.INSPECTION_CERTIFICATE,
    DmsDocType.REWEIGH_CERTIFICATE
  ];

  DocumentListTitles = DocumentListTitles;
  inspectionShipmentDetailsResp: GetInspectionShipmentDetailsResp;
  shipmentDetails: InspectionShipmentDetails;

  private unsubscriber$: Subject<void> = new Subject();
  private lastLoadedProNumberTxt: string;
  documentPhotos$: Observable<List<Photo>>;
  private docsPhotosSubscription: Subscription;
  operationPhotos$: Observable<List<Photo>>;
  inspectionPhotos$: Observable<List<Photo>>;
  private cachedShipmentDetails: GetInspectionShipmentDetailsResp;

  protected childOrParentProNumber: ProNumber;

  constructor(
    public activatedRoute: ActivatedRoute,
    public appConstantsService: AppConstantsService,
    private changeDetection: ChangeDetectorRef,
    private appNavigation: AppNavigationService,
    private matDialog: MatDialog,
    private photoDownloadService: PhotoDownloadService,
    private inspectionShipmentDetailsService: InspectionShipmentDetailsService,
    private titleService: Title,
    private snackBarHandlingService: SnackBarHandlingService,
    private photoGalleryService: PhotoGalleryService,
    private customerGuidelinesService: CustomerGuidelinesService,
    public locationService: LocationService,
    private loadingOverlayService: LoadingOverlayService,
    private shipmentDetailsCacheService: ShipmentDetailsCacheService,
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      const param: string = queryParams['useShipmentDetailsCache'];
      if (param === 'Y'){
        this.cachedShipmentDetails = this.shipmentDetailsCacheService.shipmentDetails;
      }
    })
    this.activatedRoute.params.subscribe((routeParam) => {
      const proParam: string = routeParam['pro'];
      if (!ProNumber.isValid(proParam) || this.appConstantsService.notHasSicCode()) {
        const msg: string = 'Pro from route is invalid or invalid sicCode set in appConstants';
        console.error(msg, this.activatedRoute.params, this.appConstantsService.getSicCodeOrUndefined());
        this.snackBarHandlingService.showErrorMessage(msg);
        this.appNavigation.navigateToList();
      } else {
        this.setShipmentDetails(proParam);
        this.setPhotosObservables();
        this.photoGalleryService.setLoadedPhotosForCurrentPRO(List()); // clear loaded photos
      }
    });
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
    this.docsPhotosSubscription?.unsubscribe();
  }

  get documentDocTypes(): DmsDocType[] {
    return ShipmentDetailsComponent.DOCUMENT_DOC_TYPES;
  }

  get inspectionsPhotosDocTypes(): DmsDocType[] {
    return ShipmentDetailsComponent.INSPECTIONS_PHOTOS_DOC_TYPES;
  }

  get operationsPhotosDocTypes(): DmsDocType[] {
    return ShipmentDetailsComponent.OPERATIONS_PHOTOS_DOC_TYPES;
  }

  private getPageTitle(): string {
    return AppConstantsService.PAGE_TITLE_PREFIX + ': Shipment Details for pro ' + this.childOrParentProNumber;
  }

  private setShipmentDetails(aProTxt: string): void {
    this.childOrParentProNumber = new ProNumber(aProTxt);
    RequestValidator.validateProNumber(this.childOrParentProNumber);

    if (
      !this.inspectionShipmentDetailsService.inspectionShipmentDetailsSubject.value ||
      this.inspectionShipmentDetailsService.parentProNumberSubject.value !== this.childOrParentProNumber
    ) {
      this.loadingOverlayService.setIsLoading(true);
      const cachedShipmentDetailsPro = new ProNumber(this.cachedShipmentDetails?.shipmentDetails?.shipmentId?.proNumber);
      if (this.cachedShipmentDetails && this.childOrParentProNumber.toString()===cachedShipmentDetailsPro.toString()){
        this.populateShipmentDetails(this.cachedShipmentDetails);
      } 
      this.inspectionShipmentDetailsService
        .getInspectionShipmentDetails(this.childOrParentProNumber)
        .pipe(take(1))
        .subscribe((inspectionShipmentDetailsResp: GetInspectionShipmentDetailsResp) => {
          this.shipmentDetailsCacheService.shipmentDetails = inspectionShipmentDetailsResp;
          this.populateShipmentDetails(inspectionShipmentDetailsResp);
      });
    }
  }


  private populateShipmentDetails(inspectionShipmentDetailsResp:GetInspectionShipmentDetailsResp){
    this.loadingOverlayService.setIsLoading(false);
    this.inspectionShipmentDetailsService.setInspectionShipmentDetails(inspectionShipmentDetailsResp);
    this.titleService.setTitle(this.getPageTitle());
    if (inspectionShipmentDetailsResp) {
      this.changeDetection.markForCheck();

      inspectionShipmentDetailsResp.previousInspectionDetails?.reverse();
      this.inspectionShipmentDetailsResp = inspectionShipmentDetailsResp;
      this.shipmentDetails = new InspectionShipmentDetails(this.inspectionShipmentDetailsResp?.shipmentDetails);

      // pass parent pro from the response since searched pro could be a child pro
      const parentProTxt: string = this.shipmentDetails?.shipmentId?.proNumber;
      if (ProNumber.isValid(parentProTxt)) {
        const parentPro: ProNumber = new ProNumber(parentProTxt);
        this.photoDownloadService.loadPhotoIdsFromDms(
          parentPro,
          this.shipmentDetails?.inspectionHandlingUnits.toArray()
        );

        /**
         * If we get a valid response with a pronumber, check to see if it's different from the Last Pro Number Loaded.
         * If different, then check to see if there are customer guidelines and if so, show them in a dialog box.
         * The reason for the last proNumber check is to prevent the dialog box from popping up again
         * when the guards run and load the shipment details before the next navigation (typically to the inspection screen)
         */
        if (this.lastLoadedProNumberTxt !== parentProTxt) {
          this.lastLoadedProNumberTxt = parentProTxt;

          this.displayDialogIfAny();
        }
      } else {
        throw new InspectionError('Received invalid PRO from getInspectionShipmentDetails API ->', parentProTxt);
      }
    }
  }

  private displayDialogIfAny(): void {
    const inspectionCustomerGuidelines: InspectionCustGuidelines[] =
      this.inspectionShipmentDetailsResp?.currentInspectionDetails?.custSpecificInspGuidelines;
    const hasCustSpecificInspGuidelines: boolean = inspectionCustomerGuidelines?.length > 0;
    const isRatedPalletPricingShipment: boolean = this.shipmentDetails?.ratedPalletPricingInd;
    let notificationDataList: List<NotificationData> = List();

    // check customer guideline value first because this has to be the first message
    if (hasCustSpecificInspGuidelines) {
      const guideLineMessageList: List<string> =
        this.customerGuidelinesService.buildCustomerGuidelineMessagesList(inspectionCustomerGuidelines);
      notificationDataList = notificationDataList.push(
        this.customerGuidelinesService.buildNotificationData(
          DialogHeader.CUSTOMER_GUIDELINE_HEADER,
          guideLineMessageList
        )
      );
    }

    if (isRatedPalletPricingShipment) {
      const ratedPalletPricingShipmentMessage: List<string> = List([DialogMessage.PALLET_PRICING_MESSAGE]);
      notificationDataList = notificationDataList.push(
        this.customerGuidelinesService.buildNotificationData(
          DialogHeader.GENERAL_HEADER,
          ratedPalletPricingShipmentMessage
        )
      );
    }

    if (notificationDataList && !notificationDataList.isEmpty()) {
      const multipleNotificationData: MultipleNotificationData = new MultipleNotificationData(notificationDataList);
      const dialogConfig: MatDialogConfig = <MatDialogConfig>{
        data: multipleNotificationData
      };
      this.matDialog.open(CustomerInstructionsAndPalletPricingRatedDialogComponent, dialogConfig);
    } else {
      // do nothing, there is no message to display
    }
  }

  private setPhotosObservables() {
    this.documentPhotos$ = this.photoDownloadService.documentPhotosSubject.asObservable();
    this.inspectionPhotos$ = this.photoDownloadService.inspectionPhotosSubject.asObservable();
    this.operationPhotos$ = this.photoDownloadService.operationPhotosSubject.asObservable();
  }
}
