import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, map, take} from 'rxjs/operators';
import { Photo } from '../../classes/photos/photo';
import { PhotoId } from '../../classes/photos/photo-id';
import { ProNumber } from '../../classes/pronumber';
import { CameraDialogComponent } from '../../dialogs/camera-dialog/camera-dialog.component';
import { ErrorMessageActions } from '../../enums/error-message-actions.enum';
import { AppConstantsService } from '../../services/app-constants.service';
import { IDBSpace } from '../../services/app-storage.service';
import { SnackBarHandlingService } from '../../services/snack-bar-handling.service';
import { PhotoGalleryDataImpl } from '../inspect-shipment/components/photos/photos-gallery-data-impl';
import { PhotoGalleryService } from '../photo-gallery/photo-gallery.service';
import { CameraError, CameraState, CameraStatus } from './camera-state';
import { InspectionNotificationService } from '../../services/inspection-notification.service';
import { MobileWebBrowserService } from '../../services/hardware/mobile-web-browser-service';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit, OnDestroy {
  public flash = 'off';
  public torch = false;
  public lastPhotoId: PhotoId;
  public pro: ProNumber;
  public cameraState: CameraState = new CameraState();
  public previewImage;
  public errorMessage: string;

  private snapshotSoundTrigger: HTMLAudioElement;
  private mediaStreamTrack;
  private docTypes: string[];
  private unsubscriber$: Subject<void> = new Subject();
  private readonly photoCountLimit: number = AppConstantsService.INSPECTION_PHOTOS_COUNT_LIMIT;

  captures: string[] = [];
  error: any;
  isCaptured: boolean;
  nativeWidth = 0;
  nativeHeight = 0;


  @ViewChild("video")
  public video: ElementRef;

  @ViewChild("canvas")
  public canvas: ElementRef;

  constructor(
    private mobileWebBrowserService: MobileWebBrowserService,
    private router: Router,
    private dialogRef: MatDialogRef<CameraDialogComponent>,
    private photoGalleryService: PhotoGalleryService,
    private snackBarHandlingService: SnackBarHandlingService,
    private loggingApiService: LoggingApiService
  ) {
  }

  ngOnDestroy(): void {
    this.torch = false;
    this.setTorch(this.torch);
    this.mediaStreamTrack?.stop();
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  ngOnInit(): void {
    this.snapshotSoundTrigger = document.getElementById('camera-shutter') as HTMLMediaElement;
    this.pro = new ProNumber(this.router.url.substring(this.router.url.lastIndexOf('/') + 1)); // TODO: change this

    this.photoGalleryService.onClearThumbnail.subscribe((resp) => {
      this.previewImage = undefined;
    });
    this.setupDevices()
  }

  setupDevices() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        navigator.mediaDevices.getUserMedia(({ video: { facingMode: "environment", width: { ideal: 4096 },
          height: { ideal: 2160 }}})).then((stream) => {
            this.mediaStreamTrack = stream.getVideoTracks()[0];
            this.setCameraPageSettings(stream);
            setTimeout(() => {
              // Set to 100ms. we can't set the torch back on or off instantly.
              this.setTorch(this.torch);
            }, 100);
          });
      } catch (e) {
        this.error = e;
      }
    }
  }


  onClose(): void {
    try {
      this.mediaStreamTrack?.stop();
    } catch (e) {
      this.loggingApiService.error('onClose error:', e);
      console.error('onClose error:', e);
      this.snackBarHandlingService.handleResponseError(e, ErrorMessageActions.UNKNOWN, 'mediaStreamTrack');
    }
    this.dialogRef.close();
  }

  resetCamera(){
    navigator.mediaDevices.getUserMedia(({ video: { facingMode: "environment", width: { ideal: 4096 },
    height: { ideal: 2160 } }})).then((stream) => {
      this.mediaStreamTrack = stream.getVideoTracks()[0];
      const settings = this.setCameraPageSettings(stream);
      setTimeout(() => {
        // Set to 100ms. we can't set the torch back on or off instantly.
        this.setTorch(this.torch);
      }, 100);
      setTimeout(() => { //Take the picture
        this.saveCanvasPhoto(settings);
      }, 300);
    });
  }

  setCameraPageSettings(stream: MediaStream): any {
    this.mediaStreamTrack = stream.getVideoTracks()[0];
    const settings = this.mediaStreamTrack.getSettings();
    // Access the resolution
    this.nativeWidth = settings.width;
    this.nativeHeight = settings.height;
    this.video.nativeElement.srcObject = stream;
    this.video.nativeElement.play();
    this.error = null;
    return settings;
  }

  capture() {
    this.resetCamera(); // we have to reset camera incase they have switched from portrait to landscape
  }
  
  saveCanvasPhoto(settings){
    if (!this.torch && this.flash === 'flash') {
      this.mediaStreamTrack?.applyConstraints({ advanced: [{ torch: true }] });
    } else if (this.torch && this.flash !== 'flash') {
      this.mediaStreamTrack?.applyConstraints({ advanced: [{ torch: false }] });
    }
    if (this.nativeHeight != settings.height){
      this.nativeWidth = settings.width;
      this.nativeHeight = settings.height;
    }
    this.drawImageToCanvas(this.video.nativeElement);
    const blob = this.canvas.nativeElement.toDataURL("image/jpeg");
    this.savePhoto(blob).pipe(take(1)).subscribe();
    this.snapshotSoundTrigger.play();
    this.isCaptured = true;
    this.updateCameraState();
  }

  setPhoto(idx: number) {
    this.isCaptured = true;
    var image = new Image();
    image.src = this.captures[idx];
    this.drawImageToCanvas(image);
  }

  drawImageToCanvas(image: any) {
    this.canvas.nativeElement
      .getContext("2d")
      .drawImage(image, 0, 0, this.nativeWidth, this.nativeHeight);
  }

  removeCurrent() {
    this.isCaptured = false;
  }

  savePhoto(photoBytes): Observable<boolean> {
    return this.mobileWebBrowserService.addPhoto(this.pro, photoBytes, 640, 480, null).pipe(
      take(1),
      map((addedPhoto: Photo) => {
        this.lastPhotoId = addedPhoto.id;
        return true;
      }),
      catchError((error) => {
        this.logCameraError('savePhoto() - ', error);
        this.snackBarHandlingService.handleResponseError(error, ErrorMessageActions.SAVING, `Photo`);

        return EMPTY;
      })
    );
  }

  showPhotoGallery(): Observable<any> {
    return this.photoGalleryService.openPhotoGalleryDialog(
      new PhotoGalleryDataImpl(this.mobileWebBrowserService),
      this.docTypes,
      this.lastPhotoId
    );
  }

  toggleFlash(): void {
    if (this.flash === 'off') {
      this.flash = 'flash';
    } else {
      this.flash = 'off';
    }
  }

  toggleTorch(): void {
    this.torch = !this.torch;
    this.setTorch(this.torch);
  }


  private logCameraError(functionName: string, errorMsg: string): void {
    const error = new CameraError();
    error.errorMessage = errorMsg;
    error.timeStamp = new Date();
    error.stackCall = 'CameraComponent - ' + functionName;

    this.cameraState.errorHistory.push(error);
    this.loggingApiService.error('Camera State Updated: ' + errorMsg + " - " + error.stackCall);
    console.error('Camera State Updated: ', this.cameraState);
  }

  /**
   * Setting torch for tablet user.
   * applyConstraints will fail on PC because its not available for PC
   * @param torch
   */
  private setTorch(torch: boolean) {
    if (this.mediaStreamTrack) {
      this.mediaStreamTrack
        .applyConstraints({ advanced: [{ torch: torch }] })
        .then(() => {})
        .catch((error) => {
          this.loggingApiService.error('Setting Torch error: ' + error);
          console.error('Setting Torch error: ' + error);
        });
    }
  }

  private updateCameraState(): Observable<boolean> {
    this.cameraState.supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
    this.cameraState.cameraStatus = CameraStatus.NOT_READY;

    return combineLatest(<[Observable<number>, Observable<IDBSpace>]>[
      this.mobileWebBrowserService.getPhotoCountByPro(this.pro),
      this.mobileWebBrowserService.getStorageSpace()
    ]).pipe(
      take(1),
      map(([count, storageSpace]) => {
        this.cameraState.photosTaken = count;
        this.cameraState.spaceRemainingKB = storageSpace.availableKB;
        this.updateCameraStateFromStorageLimits(this.cameraState);
        return true;
      }),
      catchError((error) => {
        this.snackBarHandlingService.handleResponseError(error, ErrorMessageActions.UPDATING, `Camera State`);

        return EMPTY;
      })
    );
  }

  private updateCameraStateFromStorageLimits(state: CameraState) {
    if (state) {
      if (state.photosTaken === this.photoCountLimit) {
        this.logCameraError('ValidateCameraState() - ', 'Photo count limit reached!');
        this.cameraState.cameraStatus = CameraStatus.ERROR;
        this.errorMessage = `The ${this.photoCountLimit} photo maximum per Inspection has been reached.  If more than ${this.photoCountLimit}  photos are needed, submit the Inspection, edit the Inspection and add up to ${this.photoCountLimit} more photos.`;
      }
      // 1 MB offset
      if (state.spaceRemainingKB <= AppConstantsService.INSPECTION_PHOTO_LOCAL_STORAGE_LIMIT_KB) {
        this.logCameraError('ValidateCameraState() - ', 'Memory is full!');
        this.cameraState.cameraStatus = CameraStatus.ERROR;
        this.errorMessage =
          'Memory limit for photo storage has been reached. Submit In Progress Inspections with photos or clean up Inspection photos to free up space.';
      }
      this.cameraState.cameraStatus = CameraStatus.READY;
    } else {
      InspectionNotificationService.appInstance?.logShouldBeFixed(
        'updateCameraStateFromStorageLimits-> given state is null and shouldnt be'
      );
    }
  }
}