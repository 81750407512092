import { SafeResourceUrl } from '@angular/platform-browser';
import { Photo } from './photo';

export class IDBPhoto {
  public photoId: string;
  public proNumber: string;
  public imageType: string;
  public status: string;
  public fileName: string;
  public createDate: Date;
  public photoContentType = '';
  public photoSizeKB: number;
  public imgSrcForHtml: string | SafeResourceUrl;;

  public static toIDBPhoto(photo: Photo): IDBPhoto {
    const idbPhoto = new IDBPhoto();
    idbPhoto.photoId = photo.id.id;
    idbPhoto.proNumber = photo.id.proNumber.formatProNumber();
    idbPhoto.imageType = photo.id.docClass;
    idbPhoto.status = photo.dmsOpCode;
    idbPhoto.fileName = photo.fileName;
    idbPhoto.photoContentType = photo.contentType;
    idbPhoto.createDate = new Date();
    idbPhoto.photoSizeKB = photo.photoSizeKB;
    idbPhoto.imgSrcForHtml = photo?.imgSrcForHtml;

    return idbPhoto;
  }
}
