import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { ActionCd } from '@xpo-ltl/sdk-common';
import {
  GetCorrectionRequestRatePreviewResp,
  GetCorrectionRequestResp,
  GetInspectionForCorrectionRequestResp,
  GetInspectionShipmentDetailsResp,
  InspectionCorrectionRequest,
  UpsertInspectionCorrectionRequestResp
} from '@xpo-ltl/sdk-inspections';
import { BehaviorSubject, catchError, Observable, take } from 'rxjs';
import { InspectionShipmentDetails } from '../../../classes/inspection-shipment-details';
import { CorrectionRequestStatus } from '../../../enums/correction-request-status.enum';
import { InspectionsApiWrapperService } from '../../../services/inspections/inspections-api-wrapper.service';

@Injectable({ providedIn: 'root' })
export class InspectionCorrectionService {
  private isSaveCancelPreviewEnabledSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isSaveCancelPreviewEnabled$: Observable<boolean> = this.isSaveCancelPreviewEnabledSubject$.asObservable();

  private isSubmitEnabledSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isSubmitEnabled$: Observable<boolean> = this.isSubmitEnabledSubject$.asObservable();

  private autoRatedPreviewSubject$: BehaviorSubject<GetCorrectionRequestRatePreviewResp> =
    new BehaviorSubject<GetCorrectionRequestRatePreviewResp>(undefined);
  autoRatedPreview$: Observable<GetCorrectionRequestRatePreviewResp> = this.autoRatedPreviewSubject$.asObservable();

  private upsertInspectionCorrectionRequestSubject$: BehaviorSubject<UpsertInspectionCorrectionRequestResp> =
    new BehaviorSubject<UpsertInspectionCorrectionRequestResp>(undefined);
  upsertInspectionCorrectionRequest$: Observable<UpsertInspectionCorrectionRequestResp> =
    this.upsertInspectionCorrectionRequestSubject$.asObservable();

  private generalInfoFormGroup: FormGroup;
  private inspectionInfoFormGroup: FormGroup;
  private commodityFormGroup: FormGroup;
  private shipmentDetails: GetInspectionShipmentDetailsResp;
  private correctionRequestResp: GetCorrectionRequestResp;
  private inspectionForCorrectionRequestResp: GetInspectionForCorrectionRequestResp;

  isAutoRatedPreviewLoaded: boolean = false;

  constructor(
    private inspectionsApiWrapperService: InspectionsApiWrapperService
  ) {}

  cancelCorrectionRequestClickedMarkInspectedNotCorrectedOnShipmentDetailToolBar(
    inspectionCorrectionRequest: InspectionCorrectionRequest
  ) {
    inspectionCorrectionRequest.correctionRequest.autoInd = false;
    inspectionCorrectionRequest.correctionRequest.statusCd = CorrectionRequestStatus.CANCELLED;
    inspectionCorrectionRequest.correctionRequest.listActionCd = ActionCd.UPDATE;

    return this.inspectionsApiWrapperService.upsertInspectionCorrectionRequest(inspectionCorrectionRequest).pipe(
      take(1),
      catchError((error) => {
        throw error;
      })
    );
  }

  setButtonSates(
    generalInfoFormGroup: FormGroup,
    inspectionInfoFormGroup: FormGroup,
    commodityFormGroup: FormGroup,
    shipmentDetails: GetInspectionShipmentDetailsResp
  ) {
    this.generalInfoFormGroup = generalInfoFormGroup;
    this.inspectionInfoFormGroup = inspectionInfoFormGroup;
    this.commodityFormGroup = commodityFormGroup;
    this.shipmentDetails = shipmentDetails;

    this.setSaveCancelPreviewButtonState();
    this.setSubmitButtonState();
  }

  get getCorrectionRequestResp() {
    return this.correctionRequestResp;
  }


  setAutoRatedPreview(preview: GetCorrectionRequestRatePreviewResp) {
    this.isAutoRatedPreviewLoaded = true;
    this.autoRatedPreviewSubject$.next(preview);
  }

  setUpsertInspectionCorrectionRequestResp(upsertResp: UpsertInspectionCorrectionRequestResp) {
    if (upsertResp) {
      this.upsertInspectionCorrectionRequestSubject$.next(upsertResp);
    }
  }

  setGetCorrectionRequestResp(correctionRequestResp: GetCorrectionRequestResp) {
    if (correctionRequestResp) {
      this.correctionRequestResp = correctionRequestResp;
    }
  }

  setGetInspectionForCorrectionRequestResp(resp: GetInspectionForCorrectionRequestResp) {
    if (resp) {
      this.inspectionForCorrectionRequestResp = resp;
    }
  }

  setSubmitButtonStateFromCorrectionRequestResp() {
    const isReasonTypeSelected: boolean =
      !!this.getCorrectionRequestResp?.inspectionCorrectionRequest?.correctionRequest?.correctionTypeCd;
    const isBolFilled: boolean =
      this.getCorrectionRequestResp?.inspectionCorrectionRequest?.correctionRequest?.originalBolInformation?.trim()
        .length > 0;
    const isRequesterCommentFilled: boolean =
      this.getCorrectionRequestResp?.inspectionCorrectionRequest?.correctionRequest?.requestorComment?.trim().length >
      0;
    const inspectionShipmentDetails: InspectionShipmentDetails = new InspectionShipmentDetails(
      this.shipmentDetails.shipmentDetails
    );
    const isShipmentRated: boolean = inspectionShipmentDetails.isRated;

    const isAllRequirementValid: boolean =
      isReasonTypeSelected && isBolFilled && isRequesterCommentFilled && isShipmentRated;

    this.isSubmitEnabledSubject$.next(isAllRequirementValid);
  }

  setInspectionShipmentDetailsResp(inspectionShipmentDetailsResp: GetInspectionShipmentDetailsResp) {
    this.shipmentDetails = inspectionShipmentDetailsResp;
  }

  private isDimsPartiallyConnected(): boolean {
    let isDimsPartiallyConnected: boolean;
    const originalDimsLength: number = this.inspectionForCorrectionRequestResp?.inspectionDimensions?.length;
    const dimCardLengthFromFormControl: number =
      this.commodityFormGroup?.value?.dimensionsFormControl?.dimensions?.length;

    isDimsPartiallyConnected =
      dimCardLengthFromFormControl === 0 ? true : dimCardLengthFromFormControl === originalDimsLength;

    return isDimsPartiallyConnected;
  }

  private setSaveCancelPreviewButtonState() {
    const isCorrectionRequestReasonSelected: boolean =
      this.generalInfoFormGroup.value.inspectionCorrectionTypeCd?.trim()?.length > 0;
    const isCommodityFormGroupValid: boolean = this.commodityFormGroup?.valid;
    const commodityFormArray: FormArray = <FormArray>this.commodityFormGroup?.get('commodityFormArray');
    let isClassTypeValid: boolean = true;

    commodityFormArray?.controls?.forEach((control: AbstractControl) => {
      if (control?.value?.classType === '0' || control?.value?.classType === undefined) {
        isClassTypeValid = false;
      }
    });

    this.isSaveCancelPreviewEnabledSubject$.next(
      isCorrectionRequestReasonSelected && isCommodityFormGroupValid && isClassTypeValid
    );
  }

  private setSubmitButtonState() {
    const hasOriginalBol: boolean = this.inspectionInfoFormGroup?.value?.originalBolInfo?.trim().length > 0;
    const hasRequestorComment: boolean = this.inspectionInfoFormGroup?.value?.requesterComment?.trim().length > 0;
    const inspectionShipmentDetails: InspectionShipmentDetails = new InspectionShipmentDetails(
      this.shipmentDetails.shipmentDetails
    );
    const isShipmentRated: boolean = inspectionShipmentDetails.isRated;

    const isAllRequirementValid: boolean =
      this.isSaveCancelPreviewEnabledSubject$.value &&
      hasOriginalBol &&
      hasRequestorComment &&
      isShipmentRated &&
      this.isDimsPartiallyConnected();

    this.isSubmitEnabledSubject$.next(isAllRequirementValid);
  }
}
