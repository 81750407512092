import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DialogWrapperService } from '../../services/dialog-wrapper.service';
import { InspectionCorrectionsComponent } from '../inspection-corrections/inspection-corrections.component';

@Component({
  selector: 'correction-request-dialog',
  templateUrl: './correction-request-dialog.component.html',
  styleUrls: ['./correction-request-dialog.component.scss']
})
export class CorrectionRequestDialogComponent implements AgRendererComponent {
  readonly DIALOG_NOT_AVAILABLE_FOR_AUTO_ELS = 'N/A Auto-ELS';
  cellValue: string; // value that will be displayed in column
  value: string; // we're not displaying this in the column but using this to get the data

  private readonly OPEN_DIALOG_FOR_CORRECTION_DETAILS = 'Open';

  constructor(
    private dialogWrapperService: DialogWrapperService,
  ) {}

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
    if (this.value === this.DIALOG_NOT_AVAILABLE_FOR_AUTO_ELS) {
      this.cellValue = this.DIALOG_NOT_AVAILABLE_FOR_AUTO_ELS;
    } else if (this.value) {
      this.cellValue = this.OPEN_DIALOG_FOR_CORRECTION_DETAILS;
    }
  }

  onOpenDialogClicked() {
    this.dialogWrapperService.alert(
      {
        titleText: `Details for Correction Request ID: ${this.value}`,
        contentComponent: InspectionCorrectionsComponent,
        hideDismissButton: true,
        injectedData: {id: +this.value, readOnly: true}
      },
      {
        disableClose: false,
        minWidth: '95vw'
      }
    );
  }

  /**
   * refresh() is from ICellRenderer(base interface)
   *
   * Get the cell to refresh. Return true if successful. Return false if not (or you don't have refresh logic),
   * then the grid will refresh the cell for you.
   */
  refresh(): boolean {
    return false;
  }
}
