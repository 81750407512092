import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChange } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GetInspectionForCorrectionRequestResp,
  GetInspectionShipmentDetailsResp,
  UpdateInspectionStatusResp,
  UpsertInspectionCorrectionRequestResp
} from '@xpo-ltl/sdk-inspections';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { InspectionShipmentDetails } from '../../../../classes/inspection-shipment-details';
import { PhotoId } from '../../../../classes/photos/photo-id';
import { ProNumber } from '../../../../classes/pronumber';
import { buildNotSubmittingInspectionWarningMessage, ConfirmMessage } from '../../../../enums/confirm-message.enum';
import { ErrorMessageActions } from '../../../../enums/error-message-actions.enum';
import { InspectionState } from '../../../../enums/inspection-state.enum';
import { InspectionStatusChangeMessage } from '../../../../enums/inspection-status-change-message.enum';
import { InspectionShipmentDetailsService } from '../../../../services/api/inspection-shipment-details.service';
import { ShipmentDetailsService } from '../../../../services/api/shipment-details.service';
import { AppNavigationService } from '../../../../services/app-navigation.service';
import { DialogWrapperService } from '../../../../services/dialog-wrapper.service';
import { MobileWebBrowserService } from '../../../../services/hardware/mobile-web-browser-service';
import { InspectionLocalStorageService } from '../../../../services/inspection-local-storage.service';
import { InspectionsApiWrapperService } from '../../../../services/inspections/inspections-api-wrapper.service';
import { LocationService } from '../../../../services/location/location.service';
import { SnackBarHandlingService } from '../../../../services/snack-bar-handling.service';
import { UserPreferencesService } from '../../../../services/user-preferences.service';
import { InspectionCorrectionService } from '../../../inspection-corrections/service/inspection-correction.service';

@Component({
  selector: 'app-shipment-details-toolbar',
  templateUrl: './shipment-details-toolbar.component.html',
  styleUrls: ['./shipment-details-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShipmentDetailsToolbarComponent implements OnChanges {
  @Input()
  shipmentDetails: InspectionShipmentDetails;

  constructor(
    private shipmentDetailsService: ShipmentDetailsService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public dialog: DialogWrapperService,
    private changeDetection: ChangeDetectorRef,
    private appNavigation: AppNavigationService,
    private inspectionStorageService: InspectionLocalStorageService,
    private snackBarHandlingService: SnackBarHandlingService,
    private inspectionShipmentDetailsService: InspectionShipmentDetailsService,
    public locationService: LocationService,
    private mobileWebBrowserService: MobileWebBrowserService,
    private inspectionCorrectionService: InspectionCorrectionService,
    private inspectionsApiWrapperService: InspectionsApiWrapperService,
    private userPreferencesService: UserPreferencesService
  ) {}

  public proNumber: string;

  public isRevoked = false;

  inspectionState$: Observable<InspectionState>;
  actionForCorrectionRequest: string;
  correctionRequestStatus: string;
  InspectionStatusChangeMessage = InspectionStatusChangeMessage;
  InspectionState = InspectionState;

  ngOnChanges(changes: { [shipmentDetails: string]: SimpleChange }) {
    if (changes.shipmentDetails?.currentValue) {
      this.actionForCorrectionRequest = this.shipmentDetails.correctionRequestsForShipment?.correctionRequestActionCd;
      this.inspectionState$ = this.inspectionShipmentDetailsService.inspectionStateSubject.asObservable();

      const currentCorrectionRequestStatus: string =
        this.shipmentDetails.correctionRequestsForShipment?.currentCorrectionRequestStatusCd;
      currentCorrectionRequestStatus
        ? (this.correctionRequestStatus = currentCorrectionRequestStatus)
        : (this.correctionRequestStatus = 'N/A');

      if (this.shipmentDetails.shipmentId?.proNumber?.trim().length > 0) {
        this.proNumber = new ProNumber(this.shipmentDetails.shipmentId.proNumber).formatProNumber();
      } else {
        this.snackBarHandlingService.showErrorMessage(
          this.snackBarHandlingService.buildDisplayErrorMessage(ErrorMessageActions.GETTING, 'PRO number')
        );
      }

      this.isRevoked = this.shipmentDetails?.removedSinceRefresh;
    }
  }

  onBackButtonClicked() {
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.appNavigation.navigateToList(null, null, null, true, null, queryParams);
    })
  }

  inspectShipment() {
    this.appNavigation.navigateToInspection(new ProNumber(this.proNumber));
  }

  openInspectionCorrection() {
    this.appNavigation.navigateToInspectionCorrection(this.proNumber);
  }

  editInspection() {
    const proNumbers = [new ProNumber(this.proNumber)];
    this.shipmentDetailsService
      .updateInspectionStatus(proNumbers, InspectionState.EDITING, false)
      .pipe(take(1))
      .subscribe((resp: UpdateInspectionStatusResp) => {
        this.appNavigation.navigateToInspection(new ProNumber(this.proNumber));
      });
  }

  markInspectedNotCorrected() {
    const currentState: InspectionState = this.inspectionShipmentDetailsService.inspectionStateSubject.getValue();
    const message: string = buildNotSubmittingInspectionWarningMessage(currentState);
    const inspectorSic: string = this.userPreferencesService.getSicShiftUserPreference().sic;

    if (this.locationService.isCorrectionsFeatureAvailable) {
      this.dialog
        .showConfirmCancelDialog(message)
        .pipe(
          take(1),
          switchMap((canProceedCancel: boolean) => {
            if (canProceedCancel) {
              this.removePhotoFromLocalStorage();

              if (this.shipmentDetails?.inspectionId) {
                // search if correction request data exists if inspection data exists
                return this.inspectionsApiWrapperService
                  .getInspectionForCorrectionRequest(this.shipmentDetails?.shipmentId?.proNumber, inspectorSic)
                  .pipe(
                    take(1),
                    switchMap((correctionReq: GetInspectionForCorrectionRequestResp) => {
                      // cancel correction request if exists
                      if (correctionReq?.inspectionCorrectionRequest?.correctionRequest?.correctionRequestId) {
                        return this.inspectionCorrectionService
                          .cancelCorrectionRequestClickedMarkInspectedNotCorrectedOnShipmentDetailToolBar(
                            correctionReq.inspectionCorrectionRequest
                          )
                          .pipe(
                            take(1),
                            map(
                              (upsertInspectionCorrectionRequestResp: UpsertInspectionCorrectionRequestResp) =>
                                canProceedCancel
                            ),
                            catchError((error) => {
                              throw error;
                            })
                          );
                      } else {
                        return of(canProceedCancel);
                      }
                    }),
                    catchError((error) => {
                      throw error;
                    })
                  );
              } else {
                // if no inspection data, we don't have to check/cancel Correction Request so we can proceed to change status
                return of(canProceedCancel);
              }
            } else {
              // do nothing. user clicked NO on the confirmation message.
              return of(canProceedCancel);
            }
          })
        )
        .subscribe((canProceedCancel: boolean) => {
          if (canProceedCancel) {
            this.changeStatus(new ProNumber(this.proNumber), InspectionState.INSPECTED_NOT_CORRECTED, true, true);
          }
        });
    } else {
      this.dialog
        .showConfirmCancelDialog(message)
        .pipe(take(1))
        .subscribe((response) => {
          if (response) {
            this.changeStatus(new ProNumber(this.proNumber), InspectionState.INSPECTED_NOT_CORRECTED, true, true);
          }
        });
    }
  }

  sendToRecommendedList() {
    this.changeStatus(new ProNumber(this.proNumber), InspectionState.RECOMMENDED);
  }

  sendToFlaggedList() {
    this.changeStatus(new ProNumber(this.proNumber), InspectionState.FLAGGED);
  }

  dismissShipment() {
    this.changeStatus(new ProNumber(this.proNumber), InspectionState.DISMISSED);
  }

  markCorrected() {
    this.dialog
      .showConfirmCancelDialog(ConfirmMessage.INSPECTED_CORRECTED)
      .pipe(take(1))
      .subscribe((response) => {
        if (response) {
          this.changeStatus(new ProNumber(this.proNumber), InspectionState.CORRECTION_SUBMITTED);
        }
      });
  }

  private changeStatus(
    proNumber: ProNumber,
    inspectionState: InspectionState,
    warningAcceptedInd: boolean = false,
    deleteInspectionData: boolean = false
  ) {
    const proNumbers = [proNumber];
    this.shipmentDetailsService
      .updateInspectionStatus(proNumbers, inspectionState, warningAcceptedInd)
      .pipe(take(1))
      .subscribe((resp: UpdateInspectionStatusResp) => {
        let reloadList = true;
        if (resp?.validationErrors?.length > 0) {
          reloadList = false;
          const validationError = resp.validationErrors[0];
          if (validationError?.message) {
            // update the shipment (in case it changed). This will also update the status
            this.inspectionShipmentDetailsService
              .getInspectionShipmentDetails(proNumber)
              .pipe(take(1))
              .subscribe((getInspectionShipmentDetailsResp: GetInspectionShipmentDetailsResp) => {
                this.inspectionShipmentDetailsService.setInspectionShipmentDetails(getInspectionShipmentDetailsResp);
              });
            if (validationError.message.startsWith('Confirmation Required')) {
              this.showConfirmDialog(proNumber, inspectionState);
            } else {
              this.dialog.showStatusChangeDialog(resp);
            }
          }
        }
        if (deleteInspectionData) {
          this.inspectionStorageService.clearInspectionData(proNumber, true);
        }
        if (reloadList) {
          this.appNavigation.navigateToList();
        } else {
          this.changeDetection.markForCheck();
        }
      });
  }

  private removePhotoFromLocalStorage() {
    this.mobileWebBrowserService
      .listPhotoIds(new ProNumber(this.proNumber))
      .pipe(
        take(1),
        switchMap((photoIds: PhotoId[]) => {
          return forkJoin(
            photoIds.map((photoId: PhotoId) => {
              return this.mobileWebBrowserService.deletePhoto(photoId);
            })
          );
        })
      )
      .subscribe((resp: boolean[]) => {
        if (resp.every((value: boolean) => value === true)) {
          // delete photo completed. do nothing.
        } else {
          // recursive call to delete left out photos
          this.removePhotoFromLocalStorage();
        }
      });
  }

  // showConfirmationDialog is only called for In-Progress inspections, warning the user
  // that it will delete the inspection (and associated data).
  // May have to change later if other types of statuses could call this.
  private showConfirmDialog(proNumber: ProNumber, newInspectionState: InspectionState) {
    this.dialog
      .showConfirmCancelDialog(buildNotSubmittingInspectionWarningMessage(InspectionState.IN_PROGRESS))
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.changeStatus(proNumber, newInspectionState, true, true);
        }
      });
  }

  keepShipment() {
    this.shipmentDetailsService
      .manuallyAddShipment(new ProNumber(this.proNumber))
      .pipe(take(1))
      .subscribe(
        () => {
          // assume the revoke happened and just update the isRevoked variable
          this.isRevoked = false;
          this.changeDetection.markForCheck();
        },
        (error) => {
          this.snackBarHandlingService.handleError('keepShipment', error);
        }
      );
  }
}
