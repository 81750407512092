import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IDBPhoto } from '../classes/photos/idbphoto';
import { Photo } from '../classes/photos/photo';
import { PhotoId } from '../classes/photos/photo-id';
import { ProNumber } from '../classes/pronumber';
import { DmsOpCode } from '../enums/dms-op-code.enum';
import { SnackBarHandlingService } from './snack-bar-handling.service';
import { PhotoEventService } from './photos/photo-event.service';

export class IDBSpace {
  availableKB: number;
  usedKB: number;
  spaceLimitKB: number;

  constructor() {
    this.availableKB = 0;
    this.usedKB = 0;
    this.spaceLimitKB = 50000; // 50MB
  }
}

@Injectable({
  providedIn: 'root'
})
export class AppStorageService {
  private static DEFAULT_IDB_VERSION_NBR = 1; // This is the schema version number of the database, there shouldn't be a need to update this.
  private static DEFAULT_IDB_NAME = 'XPO';
  private static DEFAULT_IDB_WRITEPERMISSION = 'readwrite' as IDBTransactionMode;
  private static DEFAULT_IDB_READPERMISSION = 'readonly' as IDBTransactionMode;
  private static DEFAULT_IDB_STORE = 'inspection-photos';
  private static DEFAULT_IDB_STOREKEYPATH = 'photoId';
  private static DEFAULT_IDB_STATUS_INDEX_NAME = 'status_idx';
  private static DEFAULT_IDB_PRO_INDEX_NAME = 'pro_idx';

  private _indexedDB: IDBFactory;
  private _dbName: string;
  private _indexedDBSpaceSubject;
  private _photoCountSubject;

  public static readonly PHOTO_STORAGE_MAX_MB = 50;

  constructor(private errorHandling: SnackBarHandlingService, private photoEventService: PhotoEventService) {
    this._indexedDB = window.indexedDB; // IndexedDB Object
    this._dbName = AppStorageService.DEFAULT_IDB_NAME; // IndexedDB Name
  }

  /*  Note:
   *  The initializeDB, CreateDB, and DeleteDB have been tested in various situations of upgraded versions
   *  Manual IndexedDB deletes, deletes while the application is running, and so forth
   *  Do not change unless you know all the impacts and are willing to spend a lot of time
   *  Running through all edge cases.
   **/

  public openDb(): Observable<IDBDatabase> {
    return new Observable<IDBDatabase>((observer: any) => {
      const openRequest = this._indexedDB.open(this._dbName, AppStorageService.DEFAULT_IDB_VERSION_NBR);

      let upgradeNeeded = false;
      // onupgradeneeded could happen after the onsuccess, but in all testing it doesn't appear to
      // use upgrade needed in onsuccess since it's called anyway
      openRequest.onupgradeneeded = () => {
        console.log('InitializeDB: Upgrade is needed!');
        upgradeNeeded = true;
      };

      openRequest.onsuccess = (resp) => {
        const db = openRequest.result;
        if (!db.objectStoreNames.contains(AppStorageService.DEFAULT_IDB_STORE)) {
          upgradeNeeded = true;
        } else {
          try {
            const tx = db.transaction(
              AppStorageService.DEFAULT_IDB_STORE,
              AppStorageService.DEFAULT_IDB_READPERMISSION
            );
            const store = tx.objectStore(AppStorageService.DEFAULT_IDB_STORE);
            store.index(AppStorageService.DEFAULT_IDB_STATUS_INDEX_NAME);
            store.index(AppStorageService.DEFAULT_IDB_PRO_INDEX_NAME);
          } catch (error) {
            console.log('Missing index(es). Rebuilding Database!');
            this.photoEventService.addFailedLoadLocalStorage(error, new Date(), undefined);
            upgradeNeeded = true;
          }
        }

        if (upgradeNeeded) {
          this.close(db);
          this.createDB().subscribe(() => {
            //we should not return true but the DB here or make a recursive call...
            this.openDb().subscribe((aDb: IDBDatabase) => {
              if (aDb) {
                observer.next(aDb);
                observer.complete();
              } else {
                observer.error('openDb returned a null database');
              }
            });
          });
        } else {
          observer.next(db);
          observer.complete();
        }
      };

      openRequest.onerror = (error) => {
        observer.error('Error Initializing IndexedDB: ' + openRequest.error.toString());
        this.handleError('Error Initializing IndexedDB: ' + openRequest.error.toString());
      };
    });
  }

  public createDB(): Observable<boolean> {
    console.log('Creating IndexedDB');
    this.photoEventService.addFailedLoadLocalStorage(
      new Error('Error or not, warn, recreating local indexedDb for photos'),
      new Date(),
      undefined
    );
    return new Observable<boolean>((observer: any) => {
      this.deleteDB().subscribe((response) => {
        const openRequest = this._indexedDB.open(this._dbName, AppStorageService.DEFAULT_IDB_VERSION_NBR);

        openRequest.onupgradeneeded = () => {
          const db = openRequest.result;
          const store = db.createObjectStore(AppStorageService.DEFAULT_IDB_STORE, {
            keyPath: AppStorageService.DEFAULT_IDB_STOREKEYPATH
          });
          store.createIndex(AppStorageService.DEFAULT_IDB_STATUS_INDEX_NAME, 'status', { unique: false });
          store.createIndex(AppStorageService.DEFAULT_IDB_PRO_INDEX_NAME, 'proNumber', {
            unique: false
          });
        };

        openRequest.onsuccess = () => {
          const db = openRequest.result;
          this.close(db);
          observer.next(true);
          observer.complete();
        };

        openRequest.onerror = (error) => {
          observer.error('Error Initializing IndexedDB: ' + openRequest.error.toString());
          this.handleError('Error Initializing IndexedDB: ' + openRequest.error.toString());
        };
      });
    });
  }

  public deleteDB(): Observable<boolean> {
    return new Observable((observer: any) => {
      const request = this._indexedDB.deleteDatabase(this._dbName);

      request.onsuccess = () => {
        observer.next(true);
        observer.complete();
      };

      request.onerror = () => {
        const lMsg: string = 'Error Deleting IndexedDB: ' + request.error.toString();
        observer.error(lMsg);
        this.handleError(new Error(lMsg), 'Error Deleting IndexedDB: ' + request.error.toString());
      };

      request.onblocked = () => {
        if (request) {
          const db = request.result;
          this.close(db);
        }
        const lMsg: string = 'Error Deleting IndexedDB: ' + request.error.toString();
        observer.error(lMsg);
        this.handleError(new Error(lMsg), 'Error Deleting IndexedDB: ' + request.error.toString());
      };

      request.onerror = (error) => {
        const lMsg: string = 'Error Deleting IndexedDB: ' + request.error.toString();
        observer.error(lMsg);
        this.handleError(new Error(lMsg), 'Error Deleting IndexedDB: ' + request.error.toString());
      };
    });
  }

  private handleError(error: Error | any, msg?: string) {
    console.error(msg, error);
    this.photoEventService.addFailedLoadLocalStorage(error, new Date(), undefined);
    this.errorHandling.showErrorMessage(this.errorHandling.buildGeneralErrorMessage('ERROR IN STORAGE SERVICE: ', msg));
  }

  private close(dbObject) {
    if (dbObject) {
      dbObject.close();
    }
  }

  // if item with same key exists, fails with constraint error
  public insertPhoto(photo: Photo): Observable<boolean> {
    return new Observable((observer: any) => {
      this.openDb().subscribe((db: IDBDatabase) => {
        const tx = db.transaction(
          AppStorageService.DEFAULT_IDB_STORE,
          AppStorageService.DEFAULT_IDB_WRITEPERMISSION
        ) as IDBTransaction;

        const store = tx.objectStore(AppStorageService.DEFAULT_IDB_STORE) as IDBObjectStore;

        const idbPhoto = IDBPhoto.toIDBPhoto(photo);

        const request = store.add(idbPhoto);

        tx.oncomplete = (e: any) => {
          observer.next(true);
          observer.complete();
          this.close(db);
        };

        request.onerror = (e: any) => {
          const lMsg: string = 'IndexedDB insertPhoto error: ' + e.target.error;
          observer.error(lMsg);
          this.handleError(e);
          this.close(db);
        };
        tx.onerror = (e: any) => {
          const lMsg: string = 'IndexedDB insertPhoto error: ' + e.target.error;
          observer.error(lMsg);
          this.handleError(e);
          this.close(db);
        };

        db.onerror = (e: any) => {
          observer.error('IndexedDB insertPhoto error: ' + e.target.error);
          this.handleError(e);
          this.close(db);
        };
        this.afterPhotoUpdateInDb();
      });
    });
  }

  public getPhoto(photoId: string): Observable<Photo> {
    return new Observable((observer: any) => {
      this.openDb().subscribe((db: any) => {
        const tx = db.transaction(AppStorageService.DEFAULT_IDB_STORE, AppStorageService.DEFAULT_IDB_READPERMISSION);
        const store = tx.objectStore(AppStorageService.DEFAULT_IDB_STORE);
        const request = store.get(photoId);

        request.onsuccess = (event) => {
          const photo = this.buildPhoto(event.target.result);
          observer.next(photo);
          observer.complete();
          this.close(db);
        };

        request.onerror = (e: any) => {
          observer.error('IndexedDB getPhoto error: ' + e.target.error);
          this.handleError(e);
          this.close(db);
        };

        db.onerror = (e: any) => {
          observer.error('IndexedDB getPhoto error: ' + e.target.error);
          this.handleError(e);
          this.close(db);
        };
      });
    });
  }

  public deletePhotosByProAndStatusCd(proNumber: ProNumber, statusCd: string): Observable<boolean> {
    return new Observable((observer: any) => {
      this.openDb().subscribe((db: any) => {
        const tx = db.transaction(AppStorageService.DEFAULT_IDB_STORE, AppStorageService.DEFAULT_IDB_WRITEPERMISSION);
        const store = tx.objectStore(AppStorageService.DEFAULT_IDB_STORE);

        const index = store.index(AppStorageService.DEFAULT_IDB_PRO_INDEX_NAME);
        const cursor = index.openCursor(IDBKeyRange.only(proNumber.formatProNumber()));

        cursor.onsuccess = (event) => {
          const cursorResult = event.target.result;
          if (cursorResult) {
            if (cursorResult.value.status === statusCd) {
              store.delete(cursorResult.primaryKey);
              this.photoEventService.addDeletedFromLocalStoragePhoto(cursorResult.value?.photoId, new Date());
              cursorResult.continue();
            }
          }
        };

        tx.oncomplete = (event) => {
          observer.next(true);
          observer.complete();
          this.close(db);
        };

        cursor.onerror = (e: any) => {
          observer.error('IndexedDB deletePhotosByPro error: ' + e.target.error);
          this.handleError('IndexedDB deletePhotosByPro error: ' + e.target.error);
          this.photoEventService.addFailedToDelete(undefined, new Date(), e);
          this.close(db);
        };

        db.onerror = (e: any) => {
          observer.error('IndexedDB deletePhotosByPro error: ' + e.target.error);
          this.handleError('IndexedDB deletePhotosByPro error: ' + e.target.error);
          this.photoEventService.addFailedToDelete(undefined, new Date(), e);
          this.close(db);
        };
        this.afterPhotoUpdateInDb();
      });
    });
  }

  public deletePhoto(aPhotoId: PhotoId): Observable<boolean> {
    return new Observable((observer: any) => {
      this.openDb().subscribe((db: any) => {
        const tx = db.transaction(AppStorageService.DEFAULT_IDB_STORE, AppStorageService.DEFAULT_IDB_WRITEPERMISSION);
        const store = tx.objectStore(AppStorageService.DEFAULT_IDB_STORE);

        const request = store.delete(aPhotoId.id);

        tx.oncomplete = (event) => {
          observer.next(true);
          this.photoEventService.addDeletedFromLocalStoragePhoto(aPhotoId, new Date());
          observer.complete();
          this.close(db);
        };

        request.onerror = (e: any) => {
          observer.error('IndexedDB deletePhoto error: ' + e.target.error);
          this.handleError('IndexedDB deletePhoto error: ' + e.target.error);
          this.photoEventService.addFailedToDelete(new Photo(aPhotoId), new Date(), e);
          this.close(db);
        };
        tx.onerror = (e: any) => {
          observer.error('IndexedDB deletePhoto error: ' + e.target.error);
          this.handleError('IndexedDB deletePhoto error: ' + e.target.error);
          this.photoEventService.addFailedToDelete(new Photo(aPhotoId), new Date(), e);
          this.close(db);
        };

        db.onerror = (e: any) => {
          observer.error('IndexedDB deletePhoto error: ' + e.target.error);
          this.handleError('IndexedDB deletePhoto error: ' + e.target.error);
          this.photoEventService.addFailedToDelete(new Photo(aPhotoId), new Date(), e);
          this.close(db);
        };
        this.afterPhotoUpdateInDb();
      });
    });
  }

  private getPhotoCount(): Observable<number> {
    return new Observable((observer: any) => {
      this.openDb().subscribe((db: any) => {
        const tx = db.transaction(
          AppStorageService.DEFAULT_IDB_STORE,
          AppStorageService.DEFAULT_IDB_READPERMISSION
        ) as IDBTransaction;
        const store = tx.objectStore(AppStorageService.DEFAULT_IDB_STORE) as IDBObjectStore;
        const request = store.count() as IDBRequest<number>;

        request.onsuccess = (event) => {
          observer.next(event.target['result']);
          observer.complete();
          this.close(db);
        };

        request.onerror = (e: any) => {
          observer.error('IndexedDB getPhotoCount error: ' + e.target.error);
          this.handleError('IndexedDB getPhotoCount error: ' + e.target.error);
          this.close(db);
        };

        db.onerror = (e: any) => {
          observer.error('IndexedDB getPhotoCount error: ' + e.target.error);
          this.handleError('IndexedDB getPhotoCount error: ' + e.target.error);
          this.close(db);
        };
      });
    });
  }

  // if no status is provided, will grab all status
  public getPhotosByStatus(status?: string): Observable<Photo[]> {
    return new Observable((observer: any) => {
      this.openDb().subscribe((db: any) => {
        const tx = db?.transaction(AppStorageService.DEFAULT_IDB_STORE, AppStorageService.DEFAULT_IDB_READPERMISSION);
        const store = tx.objectStore(AppStorageService.DEFAULT_IDB_STORE);
        const index = store.index(AppStorageService.DEFAULT_IDB_STATUS_INDEX_NAME);
        const cursor = index.openCursor(status);

        const resultArray: Photo[] = [];
        cursor.onsuccess = (event) => {
          const cursorResult = event.target.result;
          if (cursorResult) {
            const photo = this.buildPhoto(cursorResult.value);
            resultArray.push(photo);
            cursorResult.continue();
          } else {
            observer.next(resultArray);
            observer.complete();
            this.close(db);
          }
        };

        cursor.onerror = (e: any) => {
          observer.error('IndexedDB getPhotosByStatus error: ' + e.target.error);
          this.handleError('IndexedDB getPhotosByStatus error: ' + e.target.error);
          this.close(db);
        };

        db.onerror = (e: any) => {
          observer.error('IndexedDB getPhotosByStatus error: ' + e.target.error);
          this.handleError('IndexedDB getPhotosByStatus error: ' + e.target.error);
          this.close(db);
        };
      });
    });
  }

  public setPhotoStatusByPro(proNumber: ProNumber, status: string): Observable<boolean> {
    return new Observable<boolean>((observer: any) => {
      this.openDb().subscribe((db: any) => {
        const tx = db.transaction(AppStorageService.DEFAULT_IDB_STORE, AppStorageService.DEFAULT_IDB_WRITEPERMISSION);
        const store = tx.objectStore(AppStorageService.DEFAULT_IDB_STORE);
        const index = store.index(AppStorageService.DEFAULT_IDB_PRO_INDEX_NAME);
        const cursor = index.openCursor(proNumber.formatProNumber());

        cursor.onsuccess = (event) => {
          const cursorResult = event.target.result;
          if (cursorResult) {
            const idbPhoto: IDBPhoto = cursorResult.value;
            idbPhoto.status = status;
            store.put(idbPhoto);
            cursorResult.continue();
          }
        };

        cursor.onerror = (e: any) => {
          observer.error('IndexedDB setPhotoStatusByPro error: ' + e.target.error);
          this.handleError('IndexedDB setPhotoStatusByPro error: ' + e.target.error);
          this.close(db);
        };

        tx.oncomplete = (event) => {
          observer.next(true);
          observer.complete();
          this.close(db);
        };

        tx.onerror = (e: any) => {
          observer.error('IndexedDB setPhotoStatusByPro error: ' + e.target.error);
          this.handleError('IndexedDB setPhotoStatusByPro error: ' + e.target.error);
          this.close(db);
        };

        db.onerror = (e: any) => {
          observer.error('IndexedDB setPhotoStatusByPro error: ' + e.target.error);
          this.handleError('IndexedDB setPhotoStatusByPro error: ' + e.target.error);
          this.close(db);
        };
      });
    });
  }

  public getPhotoCountByProAndStatus(proNumber: ProNumber, status: String): Observable<number> {
    return new Observable<number>((observer: any) => {
      this.openDb().subscribe((db: any) => {
        const tx = db.transaction(AppStorageService.DEFAULT_IDB_STORE, AppStorageService.DEFAULT_IDB_READPERMISSION);
        const store = tx.objectStore(AppStorageService.DEFAULT_IDB_STORE);
        const index = store.index(AppStorageService.DEFAULT_IDB_PRO_INDEX_NAME);
        const cursor = index.openCursor(proNumber.formatProNumber());

        let count = 0;
        cursor.onsuccess = (event) => {
          const cursorResult = event.target.result;
          if (cursorResult) {
            const idbPhoto: IDBPhoto = cursorResult.value;
            if (idbPhoto.status === status) {
              count++;
            }
            cursorResult.continue();
          } else {
            observer.next(count);
            observer.complete();
            this.close(db);
          }
        };

        cursor.onerror = (e: any) => {
          observer.error('IndexedDB getPhotoCountByProAndStatus error: ' + e.target.error);
          this.handleError('IndexedDB getPhotoCountByProAndStatus error: ' + e.target.error);
          this.close(db);
        };

        db.onerror = (e: any) => {
          observer.error('IndexedDB getPhotoCountByProAndStatus error: ' + e.target.error);
          this.handleError('IndexedDB getPhotoCountByProAndStatus error: ' + e.target.error);
          this.close(db);
        };
      });
    });
  }

  public getPhotosByPro(proNumber: ProNumber): Observable<Photo[]> {
    return new Observable((observer: any) => {
      this.openDb().subscribe((db: any) => {
        const tx = db.transaction(AppStorageService.DEFAULT_IDB_STORE, AppStorageService.DEFAULT_IDB_READPERMISSION);
        const store = tx.objectStore(AppStorageService.DEFAULT_IDB_STORE);

        const index = store.index(AppStorageService.DEFAULT_IDB_PRO_INDEX_NAME);
        const cursor = index.openCursor(proNumber.formatProNumber());

        const photos: Photo[] = [];

        cursor.onsuccess = (event) => {
          const cursorResult = event.target.result;
          if (cursorResult) {
            const photo = this.buildPhoto(cursorResult.value);
            photos.push(photo);
            cursorResult.continue();
          } else {
            observer.next(photos);
            observer.complete();
            this.close(db);
          }
        };

        cursor.onerror = (e: any) => {
          observer.error('IndexedDB getPhotosByPro error: ' + e.target.error);
          this.handleError('IndexedDB getPhotosByPro error: ' + e.target.error);
          this.close(db);
        };

        db.onerror = (e: any) => {
          observer.error('IndexedDB getPhotosByPro error: ' + e.target.error);
          this.handleError('IndexedDB getPhotosByPro error: ' + e.target.error);
          this.close(db);
        };
      });
    });
  }

  private buildPhoto(idbPhoto: IDBPhoto): Photo {
    const photoId = new PhotoId(new ProNumber(idbPhoto.proNumber), idbPhoto.imageType, idbPhoto.photoId);

    const photo = new Photo(photoId);
    
    photo.setBase64dataAndContentType(idbPhoto?.imgSrcForHtml?.toString(), idbPhoto.photoContentType);
    photo.fileName = idbPhoto.fileName ? idbPhoto.fileName : '';
    photo.dmsOpCode = idbPhoto.status;
    photo.createDate = idbPhoto.createDate;
    photo.photoSizeKB = idbPhoto.photoSizeKB;
    if (idbPhoto.imgSrcForHtml) {
      photo.imgSrcForHtml = idbPhoto.imgSrcForHtml;
    }

    return photo;
  }

  public getIndexedDBSpace(): Observable<IDBSpace> {
    return new Observable<IDBSpace>((observer: any) => {
      const idbSpace = new IDBSpace();
      this.getPhotosByStatus(DmsOpCode.NEWLY_ADDED_PHOTO).subscribe((photos: Photo[]) => {
        let usedSpace = 0;
        photos.forEach((photo) => {
          usedSpace += photo.photoSizeKB;
        });
        idbSpace.usedKB = usedSpace;
        idbSpace.availableKB = parseFloat((idbSpace.spaceLimitKB - usedSpace / 1000).toFixed(2));
        observer.next(idbSpace);
        observer.complete();
      });
    });
  }

  public updateAvailableSpace(): Observable<IDBSpace> {
    if (!this._indexedDBSpaceSubject) {
      this._indexedDBSpaceSubject = new Subject<IDBSpace>();
      this.afterPhotoUpdateInDb();
    }
    return this._indexedDBSpaceSubject.asObservable();
  }

  private afterPhotoUpdateInDb() {
    const idbSpace: IDBSpace = new IDBSpace();
    this.getPhotosByStatus().subscribe((photos: Photo[]) => {
      //space
      let usedSpace = 0;
      photos.forEach((photo: Photo) => {
        usedSpace += photo.photoSizeKB;
      });
      idbSpace.usedKB = usedSpace;
      idbSpace.availableKB = parseFloat((idbSpace.spaceLimitKB - usedSpace / 1000).toFixed(2));

      this._indexedDBSpaceSubject.next(idbSpace);
      this.photoEventService.setStoredPhotos(photos);
      this.photoEventService.updatePhotoDbSpace(idbSpace);
    });
    //below we might not want to parse the DB once again
    this.getPhotoCount().subscribe((photoCount: number) => {
      this._photoCountSubject.next(photoCount);
      this.photoEventService.updateTotalPhotosCountInLocalStorage(photoCount);
    });
  }

  // review public and private method
  public updateStoredPhotosCount$(): Observable<number> {
    if (!this._photoCountSubject) {
      this._photoCountSubject = new Subject<number>();
      this.afterPhotoUpdateInDb();
    }
    return this._photoCountSubject.asObservable();
  }
}
