import { Injectable } from '@angular/core';
import { GetInspectionShipmentDetailsResp } from '@xpo-ltl/sdk-inspections';

@Injectable({
  providedIn: 'root'
})
export class ShipmentDetailsCacheService {
  _shipmentDetails: GetInspectionShipmentDetailsResp;

  constructor() {
    this._shipmentDetails = null
  }

  get shipmentDetails() {
    return this._shipmentDetails;
  }

  set shipmentDetails(details) {
    this._shipmentDetails = details;
  }
}
