import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { EMPTY, interval, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { ComparatorUtils } from '../../classes/utils/comparator-utils';
import { Photo } from '../../classes/photos/photo';
import { PhotoId } from '../../classes/photos/photo-id';
import { PhotoUtils } from '../../classes/photos/photo-utils';
import { ProNumber } from '../../classes/pronumber';
import { CameraService } from '../../components/camera/camera.service';
import { PhotoGalleryDataImpl } from '../../components/inspect-shipment/components/photos/photos-gallery-data-impl';
import { PhotoGalleryService } from '../../components/photo-gallery/photo-gallery.service';
import { DmsOpCode } from '../../enums/dms-op-code.enum';
import { DmsDocType } from '../../enums/dms-doc-type.enum';
import { InspectionState } from '../../enums/inspection-state.enum';
import { AppConstantsService } from '../app-constants.service';
import { AppStorageService, IDBSpace } from '../app-storage.service';
import { HardwareService } from './hardware-service';
import { PhotoEventService } from '../photos/photo-event.service';

@Injectable()
export class MobileWebBrowserService extends HardwareService {
  public static readonly CLEANUP_INTERVAL_MS: number = 600_000; // Check for old photos every 10 minutes.

  constructor(
    private photoGalleryService: PhotoGalleryService,
    private storageService: AppStorageService,
    private cameraService: CameraService,
    private constantsService: AppConstantsService,
    protected photoEventService: PhotoEventService
  ) {
    super(photoEventService);
    //todo: we might have a situation where if user refreshes the app before 10min, the photos are never deleted
    interval(MobileWebBrowserService.CLEANUP_INTERVAL_MS).subscribe(() => {
      this.cleanOldPhotos();
    });
  }

  public isGetac(): boolean {
    return false;
  }

  public showPendingPhotosDialogBox(): boolean {
    return true;
  }

  public exitApplication(): void {
    window.close();
  }

  public launchCamera(proNumber: ProNumber, inspectionStatus: InspectionState): void {
    this.cameraService.launchCameraDialog(proNumber, inspectionStatus);
  }

  public listThumbnails(proNumber: ProNumber): Observable<Photo[]> {
    return this.storageService.getPhotosByPro(proNumber).pipe(
      take(1),
      map((photos: Photo[]) => {
        const newPhotos: Photo[] = [];
        if (photos?.length > 0) {
          photos.forEach((photo) => {  
            if (photo.dmsOpCode === DmsOpCode.NEWLY_ADDED_PHOTO) {
              // Only show 'New' Photos
              newPhotos.push(photo);
            }
          });
        }
        newPhotos.sort((a, b) => ComparatorUtils.numberCompare(a.createDate.getTime(), b.createDate.getTime()));
        this.photoEventService.setStoredPhotos(newPhotos);
        return newPhotos;
      }),
      catchError((error) => {
        this.photoEventService.addFailedLoadLocalStorage(error, new Date(), proNumber);
        return throwError(error);
      })
    );
  }

  public getImage(photoId: PhotoId): Observable<Photo> {
    return this.storageService.getPhoto(photoId.id);
  }

  public deletePhoto(photoId: PhotoId): Observable<boolean> {
    return this.storageService.deletePhoto(photoId).pipe(
      take(1),
      tap(() => {
        this.photosUpdated(photoId.proNumber);
        this.photoEventService.addDeletedFromLocalStoragePhoto(photoId, new Date());
      })
    );
  }

  public listPhotoIds(proNumber: ProNumber): Observable<PhotoId[]> {
    return this.storageService.getPhotosByPro(proNumber).pipe(
      take(1),
      map((photos: Photo[]) => {
        const photoIds: PhotoId[] = [];
        if (photos) {
          photos.sort((a, b) => a.createDate.getTime() - b.createDate.getTime());
          photos.forEach((photo) => {
            if (photo.dmsOpCode === DmsOpCode.NEWLY_ADDED_PHOTO) {
              // Only show 'New' Photos
              photoIds.push(photo.id);
            }
          });
        }
        return photoIds;
      })
    );
  }

  public submitInspectionPhotos(proNumber: ProNumber) {
    this.storageService
      .setPhotoStatusByPro(proNumber, DmsOpCode.SUBMITTED_AND_PENDING_PHOTO)
      .pipe(take(1))
      .subscribe(
        (response) => {
          console.log('Inspection Photos Submitted!');
          this.updatePendingPhotoCount();
        },
        (error) => {
          console.error('Error Submitting Inspection Photos!');
        }
      );
  }

  public updatePendingPhotoCount() {
    this.getPendingPhotoIdsToUpload()
      .pipe(take(1))
      .subscribe((photoIds: PhotoId[]) => {
        if (photoIds) {
          this.setPendingPhotosCount(photoIds.length);
        }
      });
  }

  public getPendingPhotoIdsToUpload(): Observable<PhotoId[]> {
    const photoIds: PhotoId[] = [];
    return this.storageService.getPhotosByStatus(DmsOpCode.SUBMITTED_AND_PENDING_PHOTO).pipe(
      take(1),
      map((photos: Photo[]) => {
        if (photos) {
          photos
            .sort((photo1, photo2) => {
              // LEI-369: Inspection Photos Not Uploading to DMS in Order Taken
              return photo1.createDate.getTime() - photo2.createDate.getTime();
            })
            .forEach((photo) => {
              photoIds.push(photo.id);
            });
        }
        return photoIds;
      }),
      catchError((error) => {
        console.error(`Error getPendingPhotoIdsToUpload: ${error}`);
        return EMPTY;
      })
    );
  }

  public addPhoto(proNumber: ProNumber, photoBytes: string, width: number, height: number, filename: string = null): Observable<Photo> {
    return PhotoUtils.resizePhoto(photoBytes, width, height).pipe(
      take(1),
      switchMap((newPhotoBytes: string) => {
        const photoId = new PhotoId(proNumber, DmsDocType.INSPECTION_PHOTO);
        const photo = new Photo(photoId);
        photo.setBase64dataAndContentType(newPhotoBytes);
        photo.imgSrcForHtml = newPhotoBytes;
        photo.fileName = filename;
        photo.dmsOpCode = DmsOpCode.NEWLY_ADDED_PHOTO;
        photo.photoSizeKB = PhotoUtils.calculatePhotoSize(newPhotoBytes);

        return this.storageService.insertPhoto(photo).pipe(
          take(1),
          map((isInsertSucceeded: boolean) => {
            if (isInsertSucceeded) {
              this.photosUpdated(proNumber);
            }
            this.photoEventService.addStoredPhoto(photo, new Date());
            return photo;
          }),
          catchError((error) => {
            console.error(`Insert Photo Error: ${error}`);
            this.photoEventService.addFailedStoredToLocalStorage(photo, error, new Date());
            return throwError(error);
          })
        );
      }),
      catchError((error) => {
        //the error is currently swallowed by resizePhoto and bytes are taken
        console.error(`Resize Photo Error: ${error}`);
        return throwError(error);
      })
    );
  }

  public showPhotoGallery(proNumber: ProNumber, photoId: PhotoId): Observable<boolean> {
    return this.photoGalleryService.openPhotoGalleryDialog(
      new PhotoGalleryDataImpl(this),
      [DmsDocType.INSPECTION_PHOTO],
      photoId
    );
  }

  public removePendingPhotos() {}

  public showDesktop() {
    // guess this only adds value if running on Getac
    console.log('Not Supported in WebBrowser');
  }

  public canSetZoomLevel(): boolean {
    return false;
  }

  public setZoomLevel(zoomLevel: number) {
    console.error('Set Zoom Level Not Implemented in Web Version!');
  }

  public getPhotoCountByPro(proNumber: ProNumber): Observable<number> {
    return this.storageService.getPhotoCountByProAndStatus(proNumber, DmsOpCode.NEWLY_ADDED_PHOTO).pipe(
      take(1),
      catchError((error) => {
        console.error(`Get Photo Count By PRO Error: ${error}`);
        return EMPTY;
      })
    );
  }

  public getStorageSpace(): Observable<IDBSpace> {
    return this.storageService.getIndexedDBSpace().pipe(
      take(1),
      catchError((error) => {
        console.error(`Get Storage Space Error: ${error}`);
        return EMPTY;
      })
    );
  }

  private cleanOldPhotos() {
    const numberOfDays = this.constantsService.getPhotoCleanupStrategyDays();
    const dateLimit = moment().subtract(numberOfDays, 'days').toDate();

    this.storageService
      .getPhotosByStatus(DmsOpCode.NEWLY_ADDED_PHOTO)
      .pipe(take(1))
      .subscribe((photos: Photo[]) => {
        photos.forEach((photo: Photo) => {
          if (photo.createDate <= dateLimit) {
            //todo: photos inform user we are deleting photo or to a service to see how much its happening
            this.storageService.deletePhoto(photo.id).pipe(take(1)).subscribe();
            this.photoEventService.addDeletedFromLocalStorageCauseTooOldPhoto(photo, new Date());
          }
        });
      });
  }
}
