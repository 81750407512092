import { Injectable } from '@angular/core';
import { DocumentInfo } from '@xpo-ltl/sdk-inspections';
import { EMPTY, interval, Observable, Subscription, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { InspectionError } from '../../classes/error/inspection-error';
import { Photo } from '../../classes/photos/photo';
import { PhotoId } from '../../classes/photos/photo-id';
import { ErrorMessageActions } from '../../enums/error-message-actions.enum';
import { DocumentManagementService } from '../api/document-management-service';
import { SnackBarHandlingService } from '../snack-bar-handling.service';
import { PhotoEventService } from './photo-event.service';
import { MobileWebBrowserService } from '../hardware/mobile-web-browser-service';

@Injectable()
export class PhotoUploadService {
  //Photos are going to be uploaded every...
  private static readonly UPLOAD_INTERVAL_MS: number = 1_000; // check every second if there are photos to upload to DMS
  private static readonly MS_BETWEEN_TWO_UPLOADS: number = 300_000;
  private static readonly RETRY_INTERVAL_MS: number = 5_000; // retry every 5 seconds for 5 mins if uploading photo fails
  private static readonly RETRY_MAX_COUNT: number = 60;

  private static readonly ERROR_SRC_NAME_LOCAL_PHOTO = 'local photo';
  private static readonly PHOTO_TO_DMS = 'photo to DMS';

  private lastSendStatusDateTime: Date;
  private photoUploadFailedCount: number = 0;
  private photoUploadSchedulerSubscription: Subscription;

  //doesnt seem to be used, just stored errors, we might get rid of it
  private _photosErrorMap: Map<string, any> = new Map<string, any>();
  //timestamps to check if we fail uploading which one will be the next (oldest) to retry
  private photoIdErrorTimestampMap: Map<string, Date> = new Map<string, Date>();

  constructor(
    private mobileWebBrowserService: MobileWebBrowserService,
    private documentManagement: DocumentManagementService,
    private snackBarHandlingService: SnackBarHandlingService,
    private photoEventService: PhotoEventService
  ) {
    this.startPhotoUploadScheduler(PhotoUploadService.UPLOAD_INTERVAL_MS);
  }

  private startPhotoUploadScheduler(intervalMs: number) {
    this.photoUploadSchedulerSubscription = interval(intervalMs)
      .pipe()
      .subscribe(() => {
        if (this.photoUploadFailedCount < PhotoUploadService.RETRY_MAX_COUNT) {
          this.uploadNextPhoto();
        } else {
          this.photoUploadFailedCount = 0;
          this.snackBarHandlingService.showErrorMessage(
            'Unable to upload photos. Please refresh the app when you have internet access.'
          );
          this.photoUploadSchedulerSubscription.unsubscribe();
          this.startPhotoUploadScheduler(PhotoUploadService.UPLOAD_INTERVAL_MS);
        }
      });
  }

  private uploadNextPhoto(): void {
    // if we are in the middle of a send, then just return
    // if we have waited longer than 5 minutes, assume something failed/hung and clear the sendStatusDateTime
    let canContinue: boolean = true;
    if (this.lastSendStatusDateTime) {
      const elapsedTime = new Date().getTime() - this.lastSendStatusDateTime.getTime();
      if (elapsedTime < PhotoUploadService.MS_BETWEEN_TWO_UPLOADS) {
        canContinue = false;
      }
    }
    if (canContinue) {
      this.setLastSendStatusDateTime(new Date());
      this.mobileWebBrowserService.updatePendingPhotoCount();

      this.uploadNextPhotoImpl();
    }
  }

  private getNextPhotoToUpload(): Observable<Photo> {
    return this.mobileWebBrowserService.getPendingPhotoIdsToUpload().pipe(
      take(1),
      switchMap((photoIds: PhotoId[]) => {
        let photoIdToUpload: PhotoId;
        let oldestErrorTimestamp = Number.MAX_VALUE;
        if (photoIds?.length > 0) {
          // first process all those that don't have errors
          for (let idx = 0; idx < photoIds.length; idx++) {
            const photoId: PhotoId = photoIds[idx];
            const errorTimestamp: Date = this.photoIdErrorTimestampMap.get(photoId.id);
            if (!errorTimestamp) {
              // once we find one without an error.. just use it
              photoIdToUpload = photoId;
              break;
            } else {
              // we had an error. Keep the oldest error to use in case we don't have a non Error photoId to process
              //I think the idea is if no other photo than error to be returned, then
              // the oldest errored photo is going to be returned first
              if (errorTimestamp.getTime() < oldestErrorTimestamp) {
                oldestErrorTimestamp = errorTimestamp.getTime();
                photoIdToUpload = photoId;
              }
            }
          }
        }

        if (!photoIdToUpload) {
          // No archived-photos to process
          this.setLastSendStatusDateTime(null);
          return EMPTY;
        } else {
          return this.mobileWebBrowserService.getImage(photoIdToUpload).pipe(take(1));
        }
      })
    );
  }

  private setLastSendStatusDateTime(date: Date) {
    this.lastSendStatusDateTime = date;
  }

  private setErrorOnMaps(photo: Photo, errorMessage: any) {
    if (photo) {
      //to map
      this._photosErrorMap.set(photo.id.id, errorMessage);
      this.photoIdErrorTimestampMap.set(photo.id.id, new Date());
    }
  }

  private deletePhotoFromHardware(photo: Photo): void {
    if (photo) {
      this.photoIdErrorTimestampMap.delete(photo.id.id); // delete even if doesn't exist
      this._photosErrorMap.delete(photo.id.id); // delete even if doesn't exist
      this.mobileWebBrowserService
        .deletePhoto(photo.id)
        .pipe(
          take(1),
          catchError((error) => {
            this.snackBarHandlingService.handleResponseError(
              error,
              ErrorMessageActions.DELETING,
              PhotoUploadService.ERROR_SRC_NAME_LOCAL_PHOTO
            );
            this.setLastSendStatusDateTime(null);
            this.photoEventService.addFailedToDelete(photo, new Date(), error);
            return EMPTY;
          })
        )
        .subscribe((isDeleted: boolean) => {
          this.setLastSendStatusDateTime(null);
          if (!isDeleted) {
            const msg: string = `Hardware service delete photo returned ${isDeleted}`;
            this.snackBarHandlingService.handleResponseError(
              msg,
              ErrorMessageActions.DELETING,
              PhotoUploadService.ERROR_SRC_NAME_LOCAL_PHOTO
            );
            this.photoEventService.addFailedToDelete(photo, new Date(), new InspectionError(msg));
          }
        });
    }
  }

  private uploadNextPhotoImpl() {
    let processingPhoto: Photo;
    this.getNextPhotoToUpload()
      .pipe(
        take(1),
        switchMap((photo: Photo) => {
          if (!photo) {
            // no photo to process, set the lastSendStatusDateTime to null;
            this.setLastSendStatusDateTime(null);
            return EMPTY; //complete the observable
          } else {
            processingPhoto = photo; // saving this photo since we're using it in subscribe()
            if (!photo.getBase64data() || photo.getBase64data().trim() === ""){ // don't try to upload it if there is no file contents
              this.photoUploadFailedCount = 0; 
              this.deletePhotoFromHardware(processingPhoto);
              return;
            }
            return this.documentManagement.createArchiveDmsDocumentWithInspectionApi(processingPhoto).pipe(
              take(1),
              tap(() => {
                // if uploading photo succeeded, reset retry count if its more than 0
                if (this.photoUploadFailedCount > 0) {
                  this.photoUploadFailedCount = 0;
                }
              }),
              catchError((error) => {
                // dms error catchError
                // we set errorOnMap to stack the photoErrors and retry with the next to upload
                // as being the oldest errored one
                this.photoEventService.addFailedToUploadToDms(undefined, processingPhoto, new Date(), error);
                this.setErrorOnMaps(processingPhoto, error);
                this.setLastSendStatusDateTime(null);
                this.photoUploadFailedCount = this.photoUploadFailedCount + 1;

                // if its the first fail, unsubscribe the normal upload schedule and start it with the retry interval
                if (this.photoUploadFailedCount === 1) {
                  this.photoUploadSchedulerSubscription.unsubscribe();
                  this.startPhotoUploadScheduler(PhotoUploadService.RETRY_INTERVAL_MS);
                }

                return throwError(error);
              })
            );
          }
        }),
        catchError((error) => {
          // getNextPhotoToUpload catchError, this should never happen
          this.photoEventService.addFailedToGetNextPhoto(undefined, processingPhoto, new Date(), error);
          this.setLastSendStatusDateTime(null);
          //we want to terminate the observable here, we handled the failed to DMS in case, we dont want to
          // have that error handled by the general handler and showup to the user every minute or less
          return EMPTY;
        })
      ) //end pipe
      .subscribe((documentInfo: DocumentInfo) => {
        this.onDmsPhotoUploadSucceed(documentInfo, processingPhoto);
      });
  }

  private onDmsPhotoUploadSucceed(documentInfo: DocumentInfo, processingPhoto: Photo) {
    //archive document succeeded
    if (documentInfo?.documentUrl) {
      // if successful, delete the photo from the Getac (or Browser)
      this.photoEventService.addUploadedSuccess(
        'Photo uploaded, now removing it from browser',
        processingPhoto,
        new Date()
      );
      this.deletePhotoFromHardware(processingPhoto);
    } else {
      const msg: string = `Error saving photo to DMS! No DocumentInfo Returned!`;
      this.snackBarHandlingService.handleResponseError(
        msg,
        ErrorMessageActions.SAVING,
        PhotoUploadService.PHOTO_TO_DMS
      );
      this.photoEventService.addFailedToUploadToDms(msg, processingPhoto, new Date(), new InspectionError(msg));
      this.setErrorOnMaps(processingPhoto, 'No DocumentInfo Returned!');
      this.setLastSendStatusDateTime(null);
    }
  }
}
